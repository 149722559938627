<template>
  <div class="">
    <form autocomplete="off">
      <div class="my-card">
        <div class="row">
          <div class="form-label-group col-md-6">
            <input
              name="bankNameAr"
              id="bankNameAr"
              type="text"
              v-model="bankData.bankNameAr"
              class="form-control"
              placeholder=" "
              maxlength="50"
            />
            <label for="bankNameAr">{{ $t("banks.nameAr") }}</label>
            <img src="@/assets/images/banks.svg" />
          </div>
          <div class="form-label-group col-md-6">
            <input
              name="bankNameEn"
              id="bankNameEn"
              type="text"
              v-model="bankData.bankNameEn"
              class="form-control"
              placeholder=" "
              maxlength="50"
            />
            <label for="bankNameEn">{{ $t("banks.nameEn") }}</label>
            <img src="@/assets/images/banks.svg" />
          </div>
          <TextArea
            :className="'col-md-12'"
            :id="'bankNotes'"
            :value="bankData.bankNotes"
            :title="$t('notes')"
            :imgName="'notes.svg'"
            v-on:changeValue="bankData.bankNotes = $event"
          />
        </div>
      </div>

      <div class="form-footer">
        <button
          name="submit"
          type="submit"
          class="btn btn-submit"
          @click.prevent="addOrUpdateBank"
        >
          {{ submitName }}
        </button>
        <router-link
          :to="{
            name: 'Banks',
          }"
          class="btn btn-cancel"
        >
          {{ $t("cancel") }}
        </router-link>
      </div>
    </form>
  </div>
</template>

<script>
import TextArea from "@/components/general/TextArea.vue";

export default {
  name: "BankForm",
  components: {
    TextArea,
  },
  data() {
    return {
      language: localStorage.getItem("userLanguage") || "ar",
    };
  },
  props: ["bankData", "submitName"],
  methods: {
    async addOrUpdateBank() {
      this.$emit("addOrUpdateBank");
    },
  },
};
</script>

<style scoped lang="scss"></style>
